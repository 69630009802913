import '../App.css';
import { Link } from 'react-router-dom';
export default function Example() {
    return (
      <main class="grid min-h-full place-items-center bg-white px-6 py-40 sm:py-52 lg:px-8">
  <div class="text-center">
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl">coming soon...</h1>
  </div>
</main>
    )
  }
  