import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './component/ScrollToTop';
import Navbar from './component/Navbar.js';
import Hero from './component/Hero.js';
import NotFound from './component/NotFound.js';
import Containt from './component/containt.js';
import Footer from './component/Footer.js';


function App() {
  return (
    <Router>
      <ScrollToTop />
    <>
      <Navbar />
      <Routes>
          <Route path="/" element={
            <>
            <Hero />
            <Containt />
            <Footer/>
            </>
          } />
          <Route path="/comingsoon" element={
            <>
            <NotFound />
            </>
          } />
      </Routes>
    </>
    </Router> 
  );
}

export default App;
