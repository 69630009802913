import '../App.css';
import { Link } from 'react-router-dom';
import logo from '../assest/LOGOISMAIL.svg';
import logo1 from '../assest/logoforissa.svg';
import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);


  // Scroll event listener to change navbar color
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

  return (
    <header className={`fixed w-full z-10 transition-bg duration-300 ${isScrolled ? 'bg-white text-black border-gray-200 border-b' : 'border-b border-transparent'}`}>
      <nav className={`mx-auto flex max-w-7xl justify-between p-7 lg:px-20 ${isScrolled ? 'md:p-5 items-center' : 'md:p-5 items-normal'}`} aria-label="Global">
        <div className="flex lg:flex-1 justify-start">
          <Link to={"/"} className="flex -m-1.5 p-1.5 item-center" onClick={() => {scrollToTop()}}>
            <span className="sr-only">Issa altabbal</span>
            <img className={`w-auto hidden md:block ${isScrolled ? 'h-7' : 'h-14'}`} src={isScrolled ? logo1 : logo} alt="issa altabbal" />
            <img className={`w-auto m-auto block md:hidden ${isScrolled ? 'h-8' : 'h-12'}`} src={isScrolled ? logo1 : logo} alt="issa altabbal" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => {setMobileMenuOpen(true)}}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-10 w-10 text-black" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className={`hidden lg:flex lg:gap-x-12 ${isScrolled ? '' : 'h-fit'}`}>
          <Link to={"/comingsoon"} className="text-[18px] font-normal leading-6 text-black" onClick={() => {scrollToTop()}}>
          work
          </Link>
          <Link to={"/comingsoon"} className="text-[18px] font-normal leading-6 text-black" onClick={() => {scrollToTop()}}>
          About
          </Link>
          <Link to={`/comingsoon`} className="text-[18px] font-normal leading-6 text-black"  onClick={() => {scrollToTop()}}>
          Contact
          </Link>
          {/* <Link to={`/`} className="text-[18px] font-normal leading-6 text-black" onClick={() => {scrollToTop()}}>
          en
          </Link> */}
        </PopoverGroup>
      </nav>

      {/* ########################################################### */}

      <Dialog className="lg:hidden z-60" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-9 sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Issa altabbal</span>
              <img
                className="h-8 w-auto"
                src={logo} alt=""/>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10 text-black" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {/*<Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white opacity-60 hover:opacity-100">
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                        Product
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white opacity-60 hover:opacity-100"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>*/}
                <Link to={`/`} className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-black opacity-60 hover:opacity-100"
                onClick={() => {setMobileMenuOpen(false);scrollToTop()}}>
                    Home
                </Link>
                <Link
                  to={"/comingsoon"}
                  onClick={() => {setMobileMenuOpen(false);scrollToTop()}}
                  className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-black opacity-60 hover:opacity-100"
                >
                  Work
                </Link>
                <Link
                  to={"/comingsoon"}
                  onClick={() => {setMobileMenuOpen(false);scrollToTop()}}
                  className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-black opacity-60 hover:opacity-100"
                >
                About
                </Link>
                <Link
                  to={"/comingsoon"}
                  onClick={() => {setMobileMenuOpen(false);scrollToTop()}}
                  className="-mx-3 block rounded-lg px-3 py-2 text-2xl font-semibold leading-7 text-black opacity-60 hover:opacity-100"
                >
                Contact
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
